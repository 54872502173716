import type { NuxtError } from '#app'
import type {
  AUTHENTICATION_INTENT,
  AUTHENTICATION_PROVIDERS,
  AUTHENTICATION_TYPE,
} from '~/constants/authentication'
import type {
  BookingChangeRequestParams,
  BookingChangeRequestResponse,
  BookingChangeRequestType,
  BookingDetails,
  BookingEstimateParams,
  ExpandedRvDetails,
  Experiments,
  GeolocationData,
  LayoutParams,
  Modal,
  Nullable,
} from '~/types'
import type {
  AdditionalChargesPreviewResponse,
  BookingNotifications,
  BookingPriceResponse,
  DeliveryCostResponse,
  GetListProfile,
  UpdateProfileParams,
  UserFavouritesResponse,
  UserPermissionsResponse,
} from '~/types/rental-api-aliases'

import type {
  Toast,
} from '~/types/style-guide'

import type {
  RentalAgreement,
} from '~/types/rental-agreement'

/**
 * A collection of SSR-safe states to be re-used throughout the app. This should
 * _not_ contain any local state (just use refs in components for that).
 */

export const useLayoutParams = () => useState<LayoutParams>('layoutParams', () => ({
  hasHostFooter: false,
  showOwnerNavbar: false,
  showOnlyMyRvezyNav: false,
  hideFooter: false,
  hideHeader: false,
  hideNavigation: false,
  hideSearchForm: false,
}))

// A user's favourites.
export const useFavourites = () => useState<UserFavouritesResponse>('favourites', () => [])

// A user's permissions.
export const usePermissions = () => useState<UserPermissionsResponse>('permissions', () => [])

// Wether or not the authentication form is visible.
export const useAuthenticationModal = () => useState('authenticationModal', () => false)

export const useAuthenticationType = () => useState<Nullable<AUTHENTICATION_TYPE>>('authenticationType', () => null)

export const useAuthenticationProvider = () =>
  useState<AUTHENTICATION_PROVIDERS | undefined>('authenticationProvider', () => undefined)

export const useAuthenticationIntent = () =>
  useState<AUTHENTICATION_INTENT | undefined>('authenticationIntent', () => undefined)

// Wether or not the header search form is visible.
export const useShowHeaderSearchForm = () => useState('showHeaderSearchForm', () => false)

// Stores a user's geolocation data.
export const useGeolocationData = () => useState<Nullable<GeolocationData>>('geolocation', () => null)

// Stores the hydration state of the app.
export const useHydrationStatus = () => useState('isHydrated', () => false)

// Wether or not the expanded view is visible.
export const useExpandedView = () => useState('expandedView', () => false)

// Experiments.
export const useExperiments = () => useState<Experiments | undefined>('experiments', () => undefined)

// Ask owner question modal.
export const useShowOwnerQuestionModal = () => useState<boolean>('showOwnerQuestionModal', () => false)

// Currently selected RV.
export const useSelectedRvDetails = () => useState<Nullable<ExpandedRvDetails>>('selectedRvDetails', () => null)

// Booking Price.
export const useBookingEstimateParams = () => useState<BookingEstimateParams>('bookingEstimateParams', () => ({
  dateEnd: '',
  dateStart: '',
  protectionLevel: null,
  roadsideAssistance: true,
  adults: 1,
  children: 0,
  pets: false,
  rvUsage: null,
  addons: [],
  destination: undefined,
}))

// State used to store the PII payload from the RTB flow fields for first name, last name and phone number
// https://rvezy.atlassian.net/browse/RVZ-22365
export const useBookingPIIParams = () => useState<Nullable<UpdateProfileParams>>('bookingPIIParams', () => null)

export const useBookingEstimatePending = () => useState('bookingEstimatePending', () => false)
export const useBookingEstimateError = () => useState<Nullable<NuxtError>>('bookingEstimateError', () => null)
export const useBookingEstimateData = () => useState<Nullable<BookingPriceResponse>>('bookingEstimateData', () => null)

export const useDeliveryCostPending = () => useState<boolean>('deliveryCostPending', () => false)
export const useDeliveryCostData = () => useState<Nullable<DeliveryCostResponse>>('deliveryCostData', () => null)
export const useDeliveryCostError = () => useState<Nullable<NuxtError>>('deliveryCostError', () => null)

export const useSelectedBookingDetails = () => useState<Nullable<BookingDetails>>('selectedBookingDetails', () => null)
export const useSelectedBookingNotifications = () => useState<Nullable<BookingNotifications>>('selectedBookingNotifications', () => [])
export const useSelectedBookingUsers = () => useState<Nullable<Record<'owner' | 'renter', Nullable<GetListProfile>>>>('selectedBookingUsers', () => null)

export const usePromoCodePending = () => useState<boolean>('promoCodePending', () => false)
export const usePromoCodeError = () => useState<Nullable<NuxtError>>('promoCodeError', () => null)
export const usePromoCodeData = () => useState<Nullable<string>>('promoCode', () => '')

// Global toasts queue.
export const useToasts = () => useState<Toast[]>('toasts', () => [])

export const useModalsQueue = () => useState<{
  queue: Modal[]
  index: number
  isVisible: boolean
}>('modalsQueue', () => ({
  queue: [],
  index: -1,
  isVisible: false,
}))

export const useChangeRequestType = () => useState<Nullable<BookingChangeRequestType>>('changeRequestType', () => null)
export const useChangeRequestParams = () => useState<Nullable<Partial<BookingChangeRequestParams>>>('changeRequestParams', () => null)
export const useChangeRequestPending = () => useState<boolean>('changeRequestPending', () => false)
export const useChangeRequestError = () => useState<Nullable<NuxtError>>('changeRequestError', () => null)
export const useChangeRequestSuccess = () => useState<boolean>('changeRequestSuccess', () => false)
export const useSelectedChangeRequestData = () => useState<Nullable<BookingChangeRequestResponse>>('changeRequestData', () => null)

export const useAdditionalChargesResponse = () => useState<Nullable<AdditionalChargesPreviewResponse>>('additionalCharges', () => null)

export const useRentalAgreement = () => useState<RentalAgreement>('rentalAgreement', () => ({
  items: [],
  vouched: undefined,
}))
